import { ENDOFSEASON_PERMISSION, MAINTAIN_PERMISSION, GROWERS_SYSTEMADMINISTRATION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "System Administration",
      "search-by": "Facility,AgAdminEmailName,CurrentSeason",
      "ph-search-by": "Search by Facility ,AgAdmin Email Name , Current Season",
      "has-active": false
    },
    scopedSlots: _vm._u([_vm.hasEndOfSeasonPermission ? {
      key: "create-buttons",
      fn: function() {
        return [_c("a-button", {
          attrs: {
            "type": "primary",
            "loading": _vm.isLoading
          },
          on: {
            "click": _vm.onEndOfSeason
          }
        }, [_vm._v(" End of Season ")])];
      },
      proxy: true
    } : null, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_vm.hasMaintainPermission ? _c("a-tooltip", [_c("a-button", {
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }], null, true)
  }, [_c("text-field", {
    key: "Facility",
    attrs: {
      "data-index": "facility",
      "title": "Facility"
    }
  }), _c("text-field", {
    key: "NextIntake",
    attrs: {
      "data-index": "nextIntake",
      "title": "Next Intake"
    }
  }), _c("text-field", {
    key: "CurrentSeason",
    attrs: {
      "data-index": "currentSeason",
      "title": "Current Season"
    }
  }), _c("text-field", {
    key: "AgAdminEmailName",
    attrs: {
      "data-index": "agAdminEmailName",
      "title": "AgAdmin Email Name"
    }
  }), _c("text-field", {
    key: "DownloadCrop",
    attrs: {
      "data-index": "downloadCrop",
      "title": "Download Crop"
    }
  }), _c("text-field", {
    key: "IsDownloading",
    attrs: {
      "data-index": "isDownloading",
      "title": "Is Downloading"
    }
  }), _c("text-field", {
    key: "NextBinNumber",
    attrs: {
      "data-index": "nextBinNumber",
      "title": "Next Bin Number"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "SystemAdministration",
  inject: ["crud", "can"],
  data() {
    const apiUrl2 = "#{VUE_APP_API_URL}#";
    return {
      apiUrl: apiUrl2,
      isLoading: false
    };
  },
  computed: {
    hasEndOfSeasonPermission() {
      return this.can(ENDOFSEASON_PERMISSION);
    },
    hasMaintainPermission() {
      return this.can(MAINTAIN_PERMISSION);
    }
  },
  created() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    onEndOfSeason() {
      const customUrl = `${this.apiUrl}/grower/sys-administrations/end-of-season`;
      this.isLoading = true;
      try {
        this.crud.submitEntity("replace", customUrl);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var SystemAdministration = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.sys-administrations",
      "api-url": _vm.apiUrl,
      "edit-route": "system-administration/:id",
      "create-route": "system-administration/end-of-season",
      "page": _vm.page
    }
  }, [_c("system-administration")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    SystemAdministration
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_SYSTEMADMINISTRATION,
      menuItems: [
        {
          key: "systemadministration",
          title: "System Administration",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
